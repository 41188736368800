<template>
  <div>
    <b-modal
      id="add-truck"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AddTruck')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-2"
          :label="$t('BrandName')"
          label-for="input-2"
        >
          <vue-select
            v-model="truck.brandName"
            required
            :options="[
              'Ford', 'Chevrolet', 'Ram', 'GMC', 'Toyota', 'Nissan', 'Jeep',
              'Dodge', 'Honda', 'Mitsubishi', 'Isuzu', 'Mazda', 'Volvo',
              'Mercedes-Benz', 'Scania', 'MAN', 'Iveco', 'Renault', 'Fuso',
              'Hino', 'VW'
            ]"
            placeholder="Select"
          />
          <span
            v-show="formSubmitted && !$v.truck.brandName.required"
            style="color: red"
          >{{ $t('PleaseSelectABrand') }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('Model')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.truck.modelName.$model"
            :state="validateState('modelName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('VariantName')"
          label-for="input-1"
        >
          <b-form-input
            id="input-2"
            v-model="$v.truck.variantName.$model"
            :state="validateState('variantName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('LicencePlate')"
          label-for="input-1"
        >
          <b-form-input
            id="input-3"
            v-model="$v.truck.registrationPlate.$model"
            :state="validateState('registrationPlate')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('ChasisNumber')"
          label-for="input-1"
        >
          <b-form-input
            id="input-4"
            v-model="$v.truck.chassisNumber.$model"
            :state="validateState('chassisNumber')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-9"
          :label="$t('ProduceYear')"
          label-for="input-9"
        >
          <!-- <b-form-input
            id="input-1"
            v-model="$v.truck.year.$model"
            :state="validateState('year')"
            aria-describedby="input-1-live-feedback"
          /> -->
          <date-picker
            v-model="$v.year.$model"
            type="date"
            value-type="format"
            :placeholder="$t('Select')"
            :format="'DD-MM-YYYY'"
          />
        </b-form-group>
        <!-- <b-form-group
          id="input-group-9"
          label="Insurance Exp. Date"
          label-for="input-9"
        >
          <date-picker
            v-model="time2"
            value-type="format"
            placeholder="Select Date"
            :format="'DD-MM-YYYY'"
          />
          <span
            v-show="formSubmitted && !$v.time2.required"
            style="color: red"
          >Please select a date.</span>
        </b-form-group> -->
        <b-form-group
          id="input-group-9"
          :label="$t('Filiale')"
          label-for="input-9"
        >
          <vue-select
            v-model="store"
            required
            :options="getFiliales"
            label="storeName"
            :value="storeId"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <pre />
        <div style="width: 110%">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmitAddTruck"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 5px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

import {
  required, minLength,
} from 'vuelidate/lib/validators';

export default {
  components: {
    DatePicker,

  },
  mixins: [validationMixin],
  data() {
    return {
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      year: null,
      time1: null,
      time2: null,
      formSubmitted: false,
      storeId: null,
      truck: {
        brandName: '',
        modelName: '',
        variantName: '',
        registrationPlate: '',
        // firstRegistration: '',
        // insuranceExpirationDate: '',
        chassisNumber: '',
        storeId: null,
        year: null,
      },
      store: null,
      workInfoForm: {
        startingDate: '',
        endingDate: '',
        workload: '',
        vacationDays: '',
        employeeType: '',
        role: '',
        salary: '',
      },
    };
  },
  validations: {
    truck: {
      brandName: {
        required,
        minLength: minLength(3),
      },
      modelName: {
        required,
        minLength: minLength(3),
      },
      variantName: {
        required,
        minLength: minLength(3),
      },
      registrationPlate: {
        required,
      },
      chassisNumber: {
        required,
      },
    },
    year: {
      required,
    },
    time1: {
      required,
    },
    time2: {
      required,
    },
    store: {
      required,
    },
  },
  computed: {
    ...mapGetters(['getFiliales']),
  },
  watch: {
    store: {
      handler(value) {
        if (value.storeId == '') {
          this.truck.storeId = null;
        }
        this.truck.storeId = value.storeId;
      },
    },
    // time2(value) {
    //   // this.truck.insuranceExpirationDate = moment.utc(value, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    //   const res = moment.tz.guess()
    //   const value2 = moment.utc(value, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    //   this.truck.insuranceExpirationDate = moment.tz(value2, res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

    //   // moment().tz("America/Los_Angeles").format();
    // },
    year(value) {
      const res = moment.tz.guess()
      const value2 = moment.utc(value, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      this.truck.year = moment.tz(value2, res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
    // time1(value) {
    //   // this.truck.firstRegistration = moment.utc(value, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    //   const res = moment.tz.guess()
    //   const value2 = moment.utc(value, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    //   this.truck.firstRegistration = moment.tz(value2, res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    // },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.truck[name];
      return $dirty ? !$error : null;
    },
    onSubmitAddTruck() {
      this.formSubmitted = true;
      this.$v.truck.$touch();
      if (this.$v.truck.$anyError) {
        return;
      }
      // this.$v.time1.$touch();
      // if (this.$v.time1.$anyError) {
      //   return;
      // }
      // this.$v.time2.$touch();
      // if (this.$v.time2.$anyError) {
      //   return;
      // }
      this.$emit('click', this.truck);
      this.$refs.modal.hide();
      this.onReset()
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      this.truck.brandName = '';
      this.truck.modelName = '';
      this.truck.variantName = '';
      this.truck.registrationPlate = '';
      this.time1 = '';
      this.time2 = '';
      this.store = '';
      this.truck.chassisNumber = '';
      this.truck.year = '';
      this.year = '';
      this.formSubmitted = false
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
