<template>
  <div>
    <b-modal
      id="activate-truck"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ActivateTruck')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <p>{{ $t('ActivateTheTruck') }}</p>
        <div>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {

  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['item'],
  data() {
    return {
      activate: {
        truckId: null,
        status: true,
      },
    };
  },
  computed: {
    ...mapGetters(['getfreeTeamLeaders', 'getVacationRequests']),

  },
  watch: {
    item: {
      handler(value) {
        this.activate.truckId = value;
      },
      immediate: true,
    },


  },
  methods: {
    ...mapActions(['loadUsersFullNamesById', 'cancelVacation']),
    // AssignTeamLeader() {
    //   this.$emit('assignTeamLeader', this.form);
    //   this.$refs.modal.hide();
    //   this.onReset();
    // },
    onSubmit() {
      // console.log('activate', this.activate)
      this.$emit('click', this.activate);
      this.$refs.modal.hide();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
    },
  },
};
</script>

<style lang="scss" scoped>
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
// .form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
