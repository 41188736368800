<template>
  <div>
    <b-modal
      id="assign-store-truck"
      ref="modal"
      header-class="headerModal"
      centered
      title="Assign Store"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-2"
          label="Store"
          label-for="input-2"
        >
          <vue-select
            v-model="store"
            required
            :options="getFiliales"
            label="storeName"
            :value="storeId"
            placeholder="Select "
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <!-- <b-form-group
          id="input-group-2"
          label="Co-TeamLeader"
          label-for="input-2"
        >
          <vue-select
            v-model="coTeamLeader"
            required
            :options="getfreeCoTeamLeaders"
            label="userFullName"
            :value="userId"
            placeholder="Select TeamLeader"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group> -->
        <pre />
        <div>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="AssignTeamLeader"
          >
            {{ $t("Submit") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['truckId'],
  data() {
    return {
      store: null,
      storeId: null,
      isPhoneNumberValid: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      form: {
        storeId: '',
        truckId: '',
      },
    };
  },
  computed: {
    ...mapGetters(['getfreeTeamLeaders', 'getFiliales']),

    // getSal() {
    //   return this.$store.state.login.salutation;
    // },
  },

  watch: {
    'store.storeId': {
      handler(value) {
        this.form.storeId = value;
      },
      immediate: true,
    },
    truckId: {
      handler(value) {
        this.form.truckId = value;
      },
      immediate: true,
    },
  },
  // mounted() {
  //   this.form.truckId = this.truckId
  // },
  // created() {
  //   this.form.truckId = this.truckId
  // },
  methods: {
    ...mapActions(['loadUsersFullNamesById']),
    validateState(name) {
      const { $dirty, $error } = this.$v.filiale[name];
      return $dirty ? !$error : null;
    },
    AssignTeamLeader() {
      // this.$v.filiale.$touch();
      // if (this.$v.filiale.$anyError) {
      //   return;
      // }
      this.$emit('assignFiliale', this.form);
      this.$refs.modal.hide();
      this.onReset();
      // setTimeout(() => {
      //   this.$v.$reset();
      // }, 0);
      // this.$nextTick(() => {
      //   this.$v.$reset();
      // });

      // console.log('log', this.filiale);
    },
    // async getFullNamesById() {
    //   const res = await this.loadUsersFullNamesById({
    //     role: this.radio.teamleader,
    //   });

    // },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      // Reset our form values
      // this.form.storeId = '';
      this.form.storeId = '';
      // Trick to reset/clear native browser form validation state
      this.store = '';
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
